.react-slideshow-container {
  display: flex;
  align-items: center;
}

.react-slideshow-container .nav {
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  width: 30px;
  z-index: 10;
  text-align: center;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-slideshow-container .nav span {
  display: block;
  width: 10px;
  height: 10px;
  border-color: #000;
  border-style: solid;
  transform: rotate(45deg);
}

.react-slideshow-container .nav.disabled span {
  border-color: #666;
}

.react-slideshow-container .nav:hover {
  background: white;
  color: #666;
}

.react-slideshow-container .nav.disabled:hover {
  cursor: not-allowed;
}

.react-slideshow-container .nav:first-of-type {
  margin-right: -30px;
  border-right: none;
  border-top: none;
}

.react-slideshow-container .nav:first-of-type span {
  margin-left: 5px;
  border-right: none;
  border-top: none;
}

.react-slideshow-container .nav:last-of-type {
  margin-left: -30px;
}

.react-slideshow-container .nav:last-of-type span {
  margin-right: 5px;
  border-left: none;
  border-bottom: none;
}

.react-slideshow-container + div.indicators {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.react-slideshow-container + div.indicators > div {
  width: 7px;
  height: 7px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 50%;
  background: #ccc;
  cursor: pointer;
}

.react-slideshow-container + div.indicators > div:hover {
  background: #666;
}

.react-slideshow-container + div.indicators > div.active {
  background: #000;
}

.react-slideshow-container + div.indicators > div:last-of-type {
  margin-right: 0;
}
